<template>
  <div class="lottery-block" :class="{ active: show }" v-if="show">
    <button type="button" class="btn-close" @click="close"></button>
    <section class="product-tabs is-scroll pt-25 pb-30">
      <div
        class="card mb-20 curpoint"
        v-for="(item, index) in addressList"
        :key="index"
        @click="clickAddress(item)"
      >
        <div class="card-header title">
          <h5 class="mb-0">
            <span class="font-xxs">お届け先：</span>{{ item.sei + item.mei }}
          </h5>
        </div>
        <div class="card-body">
          <address>
            〒{{ item.zip }}<br />
            {{ item.pro_name }} {{ item.city_name }} {{ item.area_name }}
            {{ item.address }}<br />
            {{ item.building }}<br />
            TEL：{{ item.phone }}
          </address>
          <div class="btn_wrap">
            <button
              v-if="item.moren === 0"
              class="btn btn-fill-out btn-no-select"
              name=""
              value=""
              @click.stop="setDefaultAddress(item)"
            >
              デフォルトに設定
            </button>
            <button v-else class="btn btn-icon btn-fill-out" name="" value="">
              <img
                src="/assets/imgs/theme/icons/icon-check.svg"
              />デフォルトに設定中
            </button>
            <button
              class="btn btn-icon btn-fill-out btn-edit"
              name=""
              value=""
              @click.stop="goEdit(item)"
            >
              <img src="/assets/imgs/theme/icons/icon-pen.svg" />編集／登録
            </button>
          </div>
          <!-- END container -->
        </div>
      </div>

      <div class="col-lg-5">
        <button class="btn btn-icon btn-edit w-100" @click.stop="addNew">
          <img src="/assets/imgs/theme/icons/icon-plus.svg" />新規追加
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import { authApi } from '@/api'
import { ref } from '@vue/reactivity'
import { onMounted, onUnmounted } from '@vue/runtime-core'
import { useRouter, useRoute } from 'vue-router'
import {onActivated} from "vue";

export default {
  props: {},
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const show = ref(false)
    const open = () => {
      show.value = true
    }
    const close = () => {
      show.value = false
    }
    const clickAddress = (item) => {
      emit('onClickAddress', item)
    }
    const addNew = () => {
      const routerData = router.resolve({
        name: 'AddressAdd',
      })
      const windowTab = window.open(routerData.href, '_blank')
      windowTab.window.fromCart = true
      // router.push({
      //   name: 'AddressAdd',
      // })
    }
    const goEdit = (item) => {
      const routerData = router.resolve({
        name: 'AddressEdit',
        params: {
          id: item.id,
        },
      })
      const windowTab = window.open(routerData.href, '_blank')
      windowTab.window.fromCart = true
      // router.push({
      //   name: 'AddressEdit',
      //   params: {
      //     id: item.id,
      //   },
      // })
    }
    const updateAddress = (event) => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (event.data === 'addressUpdate') {
        getAddressList()
      }
    }
    const setDefaultAddress = (item) => {
      authApi.post('api/Address/setDefault', { dz_id: item.id }).then((res) => {
        getAddressList()
      })
    }

    const addressList = ref([])
    const getAddressList = () => {
      authApi.post('api/Address/index', { page: 1 }).then((res) => {
        addressList.value = res.data
      })
    }
    onActivated(() => {
      getAddressList()
    })
    onMounted(() => {
      window.addEventListener('message', updateAddress)
    })
    onUnmounted(() => {
      window.removeEventListener('message', updateAddress)
    })
    return {
      show,
      open,
      close,
      clickAddress,
      addNew,
      goEdit,
      setDefaultAddress,
      addressList,
    }
  },
}
</script>
<style scoped>
.product-tabs.is-scroll {
  overflow: auto !important;
}
.curpoint {
  cursor: pointer;
}
</style>
