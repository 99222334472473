<template>
  <div
    class="attr-detail attr-size mt-10"
    v-for="(item, index) in sku"
    :key="index"
  >
    <strong class="mr-10">{{ item.attr_name }}</strong>
    <ul class="list-filter size-filter font-small">
      <li
        v-for="(attr, attrIndex) in item.data"
        :key="attrIndex"
        class="mr-5"
        @click="selectGoodsSku(attr, index, attrIndex)"
      >
        <a :class="{'sku-active': attr.check}" style="height: auto">{{ attr.attr_value }} </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "GoodsAttrSelector",
  emits: ["selectedAttrs"],
  props: {
    sku: {
      required: true,
    }
  },
  setup(props, {emit}) {
    const sku = computed(() => {
      return props.sku
    })

    const selectGoodsSku = (attr, skuIndex, attrIndex) => {
      if (sku.value[skuIndex].data[attrIndex].check === 1) {
        return false
      }
      sku.value[skuIndex].data.forEach((sku_, skuIndex_) => {
        sku_.check = sku_.id === attr.id ? 1 : 0
      })

      let attrs = []
      sku.value.forEach((item, index_) => {
        const checkedSku = item.data.find((attr_) => {
          return attr_.check
        })
        if (checkedSku) {
          attrs.push(checkedSku.id)
        }
      })
      attrs = attrs.sort((a, b) => a - b)
      emit('selectedAttrs', attrs)
    }
    return {
      sku,
      selectGoodsSku,

    }
  }
}
</script>

<style scoped>
.sku-active {
  color: #fff !important;
  background-color: #0096E6 !important;
  border-color: #0096E6 !important;
}
</style>