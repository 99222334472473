import store from "@/store";
import { authApi } from "@/api";
import router from '@/router'

let loadingCount = 0
let loadingTimer = null
const startLoading = () => {
    $('#preloader-active').show()
    $('body').css({
        overflow: 'hidden',
    })
}

const endLoading = () => {
    $('#preloader-active').delay(450).fadeOut('slow')
    $('body').delay(450).css({
        overflow: 'visible',
    })
}

export const showLoading = () => {
    clearTimeout(loadingTimer)
    loadingTimer = setTimeout(() => {
        loadingCount = 1
        hideLoading()
    }, 5000)
    if (loadingCount === 0) {
        startLoading()
    }
    loadingCount += 1
}

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return
    }
    loadingCount -= 1
    if (loadingCount === 0) {
        clearTimeout(loadingTimer)
        endLoading()
    }
}

export const isInViewport = (element) => {
    if (!element) {
        return false
    }
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export const uuid = () => {
    let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
    for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
            case "x":
                chars[i] = Math.floor(Math.random() * 16).toString(16);
                break;
            case "y":
                chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                break;
        }
    }
    return chars.join("");
}

export const decodeBase64 = (base64) => {
    return JSON.parse(decodeURIComponent(window.atob(base64)))
}

export const shouldLoginLogout = () => {
    const shouldLogin = router.currentRoute.value.meta?.shouldLogin
    if (shouldLogin) {
        router.replace({
            name: 'Login'
        })
    }
    store.dispatch('setToken', null)
}

export const getGoodsAttrIDStr = (sku) => {
    if (!sku) {
        return ''
    }
    let skuIdArr = []
    sku.forEach((item, index_) => {
        const checkedSku = item.data.find((attr) => {
            return attr.check === 1
        })
        if (checkedSku) {
            skuIdArr.push(checkedSku.id)
        }
    })
    skuIdArr = skuIdArr.sort((a, b) => a - b)
    return skuIdArr.join()
}

export const setSEO = (params) => {
    document.getElementById("ogUrl").content = params.ogUrl || window.location.href
    document.getElementById("ogTitle").content = params.ogTitle || 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」'
    document.getElementById("ogDescription").content = params.ogDescription || 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」'
    document.getElementById("ogSiteName").content = params.ogSiteName || 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」'
    document.getElementById("ogImage").content = params.ogImage || `${window.location.origin}/assets/imgs/defaultImg.png`
    document.getElementById("twitterCard").content = params.twitterCard || 'summary'
    document.getElementById("twitterDescription").content = params.twitterDescription || 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」'
    document.getElementById("twitterImage").content = params.twitterImage || `${window.location.origin}/assets/imgs/defaultImg.png`
    document.getElementById("twitterTitle").content = params.twitterTitle || 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」'

    document.title = params.siteTitle || 'SharingLive - ライブコマースで日本地方を元気にする'
    document.getElementById("siteKeywords").content = params.siteKeywords || 'SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物,ライブ,ライブコマース,ライブ配信'
    document.getElementById("siteDescription").content = params.siteDescription || 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」'

}

export const HomeLinkToParams = (item) => {
    if (item.link == "4" && !item.link.includes(":")) {
        item.link = item.link + ":" + item.id
    }
    var items = item.link.split(':')

    if (items.count < 2) {
        return ["", ""]
    }
    let type = items.shift()
    let param = items.join(':')
    var params = {
        id: param
    }
    let url = ''
    switch (type) {
        case "0":
            url = param
            break
        case "1":
            url = 'ProductDetail'
            params = { id: param }
            break
        case "2":
            url = 'ShopDetail'
            break
        case "3":
            break
        case "4":
            break
        case "5":
            url = 'LiveMain'
            params = {
                shop_id: param,
                is_limit: item.is_limit,
            }
            break
        case "6":
            break
        default:
            return ["", ""]
    }
    return [url, params]
}


// 关注
export const liveFollow = (liveInfo) => {
    if (!store.getters.token) {
        router.push({
            name: 'Login'
        })
        return
    }
    if (liveInfo.shop_id != "") {
        const params = {
            shop_id: liveInfo.shop_id
        }
        console.log(params)
        if (liveInfo.is_followed === 1) {
            authApi.getshopcancelcoll(params).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    liveInfo.is_followed = 0
                    alert(res.mess)
                }
            })
        } else {
            authApi.getshopcoll(params).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    liveInfo.is_followed = 1
                    alert(res.mess)
                }

            })
        }

    }

    if (liveInfo.kol_id != "") {
        const params = {
            kol_id: liveInfo.kol_id
        }
        if (liveInfo.is_followed === 1) {
            authApi.getKOLCancelColl(params).then((res) => {
                if (res.status === 200) {
                    liveInfo.is_followed = 0
                    alert(res.mess)
                }
            })
        } else {
            authApi.getKOLColl(params).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    liveInfo.is_followed = 1
                    alert(res.mess)
                }
            })

        }
    }
}

export const liveFollow2 = (liveInfo) => {
    if (!store.getters.token) {
        router.push({
            name: 'Login'
        })
        return
    }
    let params = {
        schedule_id: liveInfo.id
    }
    if (liveInfo.user_id != "") {
        authApi.aliveScheduleAliveDisappointment(params).then(res => {
            if (res.status == 200) {
                alert(res.mess)
                liveInfo.user_id = ""
            }
        })
    } else {
        authApi.aliveScheduleAliveAppointment(params).then(res => {
            console.log(res)
            if (res.status == 200) {
                alert(res.mess)
                liveInfo.user_id = "1"
            }
        })
    }
}

export const randomString = (N = 8) => {
    const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    return Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('')
}

export const urlParamsEncode = (params) => {
    return encodeURIComponent(JSON.stringify(params))
}
export const urlParamsDecode = (str) => {
    return JSON.parse(decodeURIComponent(str))
}

export const getAliveAppointmentTitle = (alive) => {
    if(new Date(alive.start_time).getTime() > new Date().getTime()) {
        return alive.user_id !== '' ? '予約中' : '予約'
    } else {
        return alive.user_id !== '' ? 'お気に入り中' : 'お気に入り'
    }
}

export const editorI18nCn = {
    listModule: {
        unOrderedList: "无序列表",
        orderedList: "有序列表",
    },
    common: {
        ok: '确定',
        delete: '删除',
        enter: '回车',
    },

    blockQuote: {
        title: '引用',
    },
    codeBlock: {
        title: '代码块',
    },
    color: {
        color: '文字颜色',
        bgColor: '背景色',
        default: '默认颜色',
        clear: '清除背景色',
    },
    divider: {
        title: '分割线',
    },
    emotion: {
        title: '表情',
    },
    fontSize: {
        title: '字号',
        default: '默认字号',
    },
    fontFamily: {
        title: '字体',
        default: '默认字体',
    },
    fullScreen: {
        title: '全屏',
    },
    header: {
        title: '标题',
        text: '正文',
    },
    uploadImgModule: {
        uploadImage: "上传图片",
        uploadError: '{{fileName}} 上传出错',
    },
    image: {
        netImage: '网络图片',
        delete: '删除图片',
        edit: '编辑图片',
        viewLink: '查看链接',
        src: '图片地址',
        desc: '图片描述',
        link: '图片链接',
    },
    indent: {
        decrease: '减少缩进',
        increase: '增加缩进',
    },
    justify: {
        left: '左对齐',
        right: '右对齐',
        center: '居中对齐',
        justify: '两端对齐',
    },
    lineHeight: {
        title: '行高',
        default: '默认行高',
    },
    link: {
        insert: '插入链接',
        text: '链接文本',
        url: '链接地址',
        unLink: '取消链接',
        edit: '修改链接',
        view: '查看链接',
    },
    videoModule: {
        delete: '删除视频',
        uploadVideo: '上传视频',
        insertVideo: '插入视频',
        videoSrc: '视频地址',
        insertPlaceHolder: '视频文件 url 或第三方 <iframe>',
        ok: '确定',
        editSize: '修改尺寸',
        width: '宽度',
        height: '高度',
    },
    textStyle: {
        bold: '粗体',
        clear: '清除格式',
        code: '行内代码',
        italic: '斜体',
        sub: '下标',
        sup: '上标',
        through: '删除线',
        underline: '下划线',
    },
    tableModule: {
        deleteCol: '删除列',
        deleteRow: '删除行',
        deleteTable: '删除表格',
        widthAuto: '宽度自适应',
        insertCol: '插入列',
        insertRow: '插入行',
        insertTable: '插入表格',
        header: '表头',
    },
    undo: {
        undo: '撤销',
        redo: '重做',
    },
    todo: {
        todo: '待办',
    },
}
export const editorI18nJa = {
    listModule: {
        unOrderedList: "番号なしリスト",
        orderedList: "番号付きリスト",
    },
    common: {
        ok: '確定',
        delete: '削除',
        enter: 'エンター',
    },

    blockQuote: {
        title: '引用',
    },
    codeBlock: {
        title: 'コードブロック',
    },
    color: {
        color: '文字色',
        bgColor: '背景色',
        default: 'デフォルト色',
        clear: '背景色をクリア',
    },
    divider: {
        title: '区切り線',
    },
    emotion: {
        title: '表情',
    },
    fontSize: {
        title: 'フォントサイズ',
        default: 'デフォルトフォントサイズ',
    },
    fontFamily: {
        title: 'フォント',
        default: 'デフォルトフォント',
    },
    fullScreen: {
        title: '全画面',
    },
    header: {
        title: '見出し',
        text: '本文',
    },
    uploadImgModule: {
        uploadImage: "画像をアップロード",
        uploadError: '{{fileName}} アップロードエラー',
    },
    image: {
        netImage: 'ネットワーク画像',
        delete: '画像を削除',
        edit: '画像を編集',
        viewLink: 'リンクを見る',
        src: '画像アドレス',
        desc: '画像の説明',
        link: '画像リンク',
    },
    indent: {
        decrease: 'インデントを減らす',
        increase: 'インデントを増やす',
    },
    justify: {
        left: '左揃え',
        right: '右揃え',
        center: '中央揃え',
        justify: '両端揃え',
    },
    lineHeight: {
        title: '行高',
        default: 'デフォルト行高',
    },
    link: {
        insert: 'リンクを挿入',
        text: 'リンクテキスト',
        url: 'リンクアドレス',
        unLink: 'リンクを解除',
        edit: 'リンクを編集',
        view: 'リンクを見る',
    },
    videoModule: {
        delete: 'ビデオを削除',
        uploadVideo: 'ビデオをアップロード',
        insertVideo: 'ビデオを挿入',
        videoSrc: 'ビデオアドレス',
        insertPlaceHolder: 'ビデオファイル url または第三者 <iframe>',
        ok: '確定',
        editSize: 'サイズを変更',
        width: '幅',
        height: '高さ',
    },
    textStyle: {
        bold: 'ボールド',
        clear: '書式をクリア',
        code: 'インラインコード',
        italic: 'イタリック',
        sub: '下付き',
        sup: '上付き',
        through: '取り消し線',
        underline: '下線',
    },
    tableModule: {
        deleteCol: '列を削除',
        deleteRow: '行を削除',
        deleteTable: '表を削除',
        widthAuto: '幅を自動調整',
        insertCol: '列を挿入',
        insertRow: '行を挿入',
        insertTable: '表を挿入',
        header: 'ヘッダ',
    },
    undo: {
        undo: '取り消し',
        redo: 'やり直し',
    },
    todo: {
        todo: '待ち',
    },
}