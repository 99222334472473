<template>
  <div class="goods-price" v-if="subscribe">
    <strong class="uni-bold discount red-text">
      合計金額：{{ $NumberToLocaleString((discount + option)*goodsNumber) }}円
    </strong>
  </div>
  <div class="goods-price" v-else>
    <div class="uni-bold" v-if="original !== discount">
      <strong class="origin">
        合計金額：{{ $NumberToLocaleString((original + option)*goodsNumber) }}円
      </strong>
    </div>
    <i class="fi-rs-arrow-right right" v-if="original !== discount"></i>
    <strong class="uni-bold" v-if="original === discount">
      合計金額：{{ $NumberToLocaleString((discount + option)*goodsNumber) }}円
    </strong>
    <strong class="uni-bold discount red-text" v-else>
      合計金額：{{ $NumberToLocaleString((discount + option)*goodsNumber) }}円
    </strong>
  </div>
</template>

<script>
import {computed} from 'vue'

export default {
  props: {
    original: {
      type: Number,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    option: {
      type: Number,
      default: 0,
    },
    subscribe: {
      type: Number,
      default: 0,
    },
    goodsNumber: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    return {}
  },
}
</script>

<style scoped>
.goods-price {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  align-items: center;
}

.origin {
  text-decoration: line-through;
}

.right {
  font-weight: normal;
  color: #888888;
  padding: 0 2px;
}

.discount {
  color: #ff3232;
}
</style>
