import { createStore } from 'vuex'
import { authApi } from "@/api";
import { showLoading, hideLoading, shouldLoginLogout } from "@/utils/utils";
import { collGoodsList, followShopOrKOLList, getCartCount, getCartList } from "@/utils/locally";
import router from '@/router'

export default createStore({
  state: {
    homeInfo: null,
    menuInfo: null,
    searchInfo: null,
    token: localStorage.getItem('token') || null,
    userInfo: null,
    cartList: [],
    cartInfo: null,
    cartPrice: 0,
    cartCount: 0,
    followShopOrKOLList: [],
    subscribeCart: null,
    pageKey: 0,
    liveData:null,
    nowTimeDate:new Date()
  },
  getters: {
    homeInfo: (state) => state.homeInfo,
    menuInfo: (state) => state.menuInfo,
    searchInfo: (state) => state.searchInfo,
    token: (state) => state.token,
    userInfo: (state) => state.userInfo,
    cartList: (state) => state.cartList,
    cartInfo: (state) => state.cartInfo,
    cartPrice: (state) => state.cartPrice,
    cartCount: (state) => state.cartCount,
    followShopOrKOLList: (state) => state.followShopOrKOLList,
    subscribeCart: (state) => state.subscribeCart,
    pageKey: (state) => state.pageKey,
    liveData: (state) => state.liveData,
    nowTimeDate: (state) => state.nowTimeDate,
  },
  mutations: {
    updateHomeInfo(state) {
      showLoading()
      authApi
        .homeInfo()
        .then((res) => {
          state.homeInfo = res.data
        }).finally(() => {
        hideLoading();
      })
    },
    updateMenuInfo(state) {
      showLoading()
      authApi
        .menuInfo()
        .then((res) => {
          state.menuInfo = res.data
        }).finally(() => {
        hideLoading();
      })
    },
    updateSearchInfo(state) {
      showLoading()
      authApi
        .searchInfo()
        .then((res) => {
          state.searchInfo = res.data
        }).finally(() => {
        hideLoading();
      })
    },
    setToken(state, data) {
      state.token = data || null
    },
    setUserInfo(state) {
      if (state.token) {
        authApi.post('api/MemberInfo/readprofile').then((res) => {
          if (res.status === 200) {
            state.userInfo = res.data
          } else {
            shouldLoginLogout()
          }

        }).catch((err) => {
          console.error(err)
          shouldLoginLogout()
        })
      } else {
        state.userInfo = null
      }

    },
    mergeLocalData (state) {
      const localList = getCartList()
      authApi.post('api/Cart/mergeCart', { goods_list:localList }).then((res) => {
        localStorage.removeItem('LocalCart')
      })
      const goodsList = collGoodsList()
      const newGoodsList = goodsList.map(item => {
        return item.goods_id
      })
      const shopOrKolList = followShopOrKOLList()
      const params = { channel_list: shopOrKolList, goods_list: newGoodsList }
      authApi.post('api/v2/MemberColl/mergeColl', params).then(() => {
        localStorage.removeItem('LocalGoodsColl')
        localStorage.removeItem('LocalShopsOrKOLColl')
        this.commit('updateCartList')
      })
    },
    updateCartList(state, data) {
      if (!data) {
        data = { page: 1 }
      }
      if (state.token) {
        authApi.post('api/Cart/index', data).then((res) => {
          state.cartInfo = res.data
          state.cartList = res.data.cart_info
          this.commit('calculateCartPrice', state.cartList)
        })
        authApi.post('api/Cart/goodsCount').then((res) => {
          state.cartCount = res.data.cart_goods_count
        })
      } else {
        const localList = getCartList()
        authApi.post('api/Cart/indexLocal', { goods_list: localList }).then((res) => {
          state.cartInfo = res.data
          state.cartList = res.data.cart_info
          this.commit('calculateCartPrice', state.cartList)
        })
        state.cartCount = getCartCount()
      }
    },
    calculateCartPrice(state, data) {
      if (data) {
        let total = 0;
        data.forEach((shopItem) => {
          shopItem.goodres.forEach((cartItem) => {
            total += (parseInt(cartItem.discount_price) + cartItem.option_price) * Number(cartItem.num)
          })
        })
        state.cartPrice = total
      }
    },
    updateFollowShopOrKOLList(state, data) {
      if (!data) {
        data = { page: 1 }
      }
      authApi.collectShopOrKOLList(data).then(res => {
        state.followShopOrKOLList = res.data.items
      })
    },
    plusPageKey(state) {
      state.pageKey = state.pageKey + 1
    },
    updateLiveData(state, data) {
      state.liveData = data
    },
    startNowTimeDate(state, data) {
      setInterval(()=>{
        state.nowTimeDate = new Date()
      },1000)
    },
  },
  actions: {
    updateHomeInfo({ commit }) {
      commit("updateHomeInfo");
    },
    updateMenuInfo({ commit }) {
      commit("updateMenuInfo");
    },
    updateSearchInfo({ commit }) {
      commit("updateSearchInfo");
    },
    updateInfo({ commit }) {
      commit("updateHomeInfo");
      commit("updateMenuInfo");
      commit("updateSearchInfo");
    },
    setToken({ commit }, data) {
      if (data) {
        localStorage.setItem('token', data)
        commit('mergeLocalData')
      } else {
        localStorage.removeItem('token')
      }
      commit("setToken", data);
      commit("setUserInfo");
    },
    setUserInfo({ commit }) {
      commit("setUserInfo");
    },
    updateCartList({ commit }) {
      commit("updateCartList")
    },
    updateFollowShopOrKOLList({ commit }) {
      commit("updateFollowShopOrKOLList")
    },
    plusPageKey({ commit }) {
      commit("plusPageKey")
    },
    updateLiveData({ commit },data) {
      commit("updateLiveData", data)
    },
    startNowTimeDate({ commit }) {
      commit("startNowTimeDate")
    },
  },
  modules: {}
})
