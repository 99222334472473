<template>
  <div class="sl-image-body" :style="{ width: width }">
    <div class="sl-image-padding" :style="paddingStyle"></div>
    <img
      :src="src"
      class="sl-image-img"
      :style="imagStyle"
      :alt="alt"
      :class="imageClass"
    />
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    ratio: {
      type: Number,
      default: null,
    },
    src: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: 'cover',
    },
    alt: {
      type: String,
      default: '',
    },
    imageClass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const paddingStyle = computed(() => {
      return {
        width: props.width,
        'padding-bottom': props.height
          ? props.height
          : `calc(${props.width} * ${props.ratio})`,
      }
    })
    const imagStyle = computed(() => {
      return {
        'object-fit': props.fit,
      }
    })

    return { paddingStyle, imagStyle }
  },
}
</script>

<style scoped>
.sl-image-body {
  position: relative;
  display: inline-block;
}
.sl-image-img {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  display: block;
}
.shooting-room-bg-img {
  margin: 0 auto 20px auto;
}
</style>
