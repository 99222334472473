<template>
  <div class="share-modal-body" id="shareModalBody">
    <div class="clearfix share-modal-link-body">
      <div class="share-modal-item">
        <a
          class="share-modal-icon-box"
          :href="`https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`"
          target="_blank"
        >
          <img src="/assets/imgs/share-twitter.png" class="share-modal-icon" />
        </a>
        <div class="share-modal-name cWhite">Twitter</div>
      </div>
      <div class="share-modal-item">
        <a
          class="share-modal-icon-box"
          :href="`https://social-plugins.line.me/lineit/share?url=${url}`"
          target="_blank"
        >
          <img src="/assets/imgs/share-line.png" class="share-modal-icon" />
        </a>
        <div class="share-modal-name cWhite">LINE</div>
      </div>
      <div class="share-modal-item">
        <a
          class="share-modal-icon-box"
          :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
          target="_blank"
        >
          <img src="/assets/imgs/share-facebook.png" class="share-modal-icon" />
        </a>
        <div class="share-modal-name cWhite">facebook</div>
      </div>
      <!-- <div class="share-modal-item">
        <a
          class="share-modal-icon-box"
          :href="`mailto:?subject=${text}&body=${url}`"
          target="_blank"
        >
          <img src="/assets/imgs/share-mail.png" class="share-modal-icon" />
        </a>
        <div class="share-modal-name cWhite">メール</div>
      </div> -->
      <!-- <div class="share-modal-item">
        <a class="share-modal-icon-box" href="javascript:;" @click="copy">
          <img src="/assets/imgs/share-link.png" class="share-modal-icon" />
        </a>
        <div class="share-modal-name cWhite">リンクをコピー</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShareModal',
  props: {
    url: { type: String, default: '' },
    text: { type: String, default: '' },
    hashtags: { type: String, default: '' },
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  //   emits: ['update:isOpen'],
  components: {},
  methods: {
    closeDialog(e) {
      this.$emit('update:isOpen', e)
    },
    copy() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.url).then(() => {
          alert('コピーしました')
        })
      } else {
        alert('コピー失敗しました')
      }
    },
    cancel() {
      this.$emit('update:isOpen', false)
    },
    afterLeave() {
      this.$emit('afterLeave')
    },
    open() {
      window.Swal.fire({
        title: '共有',
        html: $('#shareModalBody').html(),
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
      })
    },
  },
})
</script>

<style scoped>
.share-modal-link-body {
  width: 100%;
  padding: 0 10px;
}
.share-modal-body {
  width: 100%;
  background: #191919;
  display: none;
}
.share-modal-item {
  width: 33%;
  padding: 20px 1% 8px 1%;
  text-align: center;
  float: left;
}
.share-modal-icon-box {
  width: 100%;
  display: block;
}
.share-modal-icon {
  width: 100%;
  max-width: 50px;
  height: auto;
}
.share-modal-name {
  font-size: 12px;
  line-height: 14px;
  padding-top: 5px;
}
.share-modal-cancel {
  width: 100%;
  height: 69px;
  background-color: #4b4a4b;
  color: #fff;
}
</style>
