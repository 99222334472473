export const addToCart = (params) => {
  localStorage.removeItem('AgeCheckAgree')
  return new Promise((resolve, reject) => {
    params['add_time'] = new Date().toJSON().replace('T', ' ').split('.')[0]
    let localCart = localStorage.getItem('LocalCart');
    if (!localCart) {
      localCart = []
    } else {
      localCart = JSON.parse(localCart)
    }
    params['id'] = localCart.length + 1
    let index = localCart.findIndex(item => {
      const keys1 = Object.keys(item);
      const keys2 = Object.keys(params);
      if (keys1.length !== keys2.length) {
        return false
      }
      let itemOptionsStr = JSON.stringify(item.goods_options ?? [])
      let paramsOptionsStr = JSON.stringify(params.goods_options ?? [])
      return item.goods_id === params.goods_id &&
        item.goods_attr === params.goods_attr &&
        item.kol_id === params.kol_id &&
        item.live_type === params.live_type &&
        itemOptionsStr === paramsOptionsStr;
    })
    if (index >= 0) {
      localCart[index].num += params.num
    } else {
      localCart.push(params)
    }
    localStorage.setItem('LocalCart', JSON.stringify(localCart))
    const res = {
      status: 200,
      mess: "カートに商品を追加しました。"
    }
    resolve(res)
  })
}

export const removeFromCart = (item) => {
  return new Promise((resolve, reject) => {
    let localCart = localStorage.getItem('LocalCart')
    if (!localCart) {
      localCart = []
    } else {
      localCart = JSON.parse(localCart)
    }
    let newCart = localCart.filter(oldItem => {
      let itemOptionsStr = JSON.stringify(item.goods_options ?? [])
      let oldItemOptionsStr = JSON.stringify(oldItem.goods_options ?? [])
      return !(item.goods_id === oldItem.goods_id &&
        item.goods_attr === oldItem.goods_attr &&
        item.kol_id === oldItem.kol_id &&
        item.live_type === oldItem.live_type &&
        itemOptionsStr === oldItemOptionsStr);
    })
    localStorage.setItem('LocalCart', JSON.stringify(newCart))
    const res = {
      status: 200,
      mess: ""
    }
    resolve(res)
  })
}

export const getCartList = () => {
  // 获取本地购物车数量
  let localCart = localStorage.getItem('LocalCart');
  if (localCart) {
    localCart = JSON.parse(localCart)
    return localCart
  }
  return []
}

export const getCartCount = () => {
  // 获取本地购物车数量
  let localCart = localStorage.getItem('LocalCart');
  let count = 0;
  if (localCart) {
    localCart = JSON.parse(localCart)
    localCart.forEach(cart => {
      count += cart.num
    })
  }
  return count
}

export const addGoodsCollectd = (goods) => {
  return new Promise((resolve, reject) => {
    let localGoodsColl = localStorage.getItem('LocalGoodsColl')
    if (!localGoodsColl) {
      localGoodsColl = []
    } else {
      localGoodsColl = JSON.parse(localGoodsColl)
    }
    let index = localGoodsColl.findIndex(item => {
      return goods.goods_id === item.goods_id
    })
    if (index < 0) {
      localGoodsColl.push(goods)
      localStorage.setItem('LocalGoodsColl', JSON.stringify(localGoodsColl))
    }
    const res = {
      status: 200,
      mess: "お気に入りに追加しました。"
    }
    resolve(res)
  })
}

export const removeGoodsCollectd = (goods) => {
  return new Promise((resolve, reject) => {
    let localGoodsColl = localStorage.getItem('LocalGoodsColl')
    if (!localGoodsColl) {
      localGoodsColl = []
    } else {
      localGoodsColl = JSON.parse(localGoodsColl)
    }
    let newGoodsColl = localGoodsColl.filter(oldItem => {
      return oldItem.goods_id !== goods.goods_id
    })
    localStorage.setItem('LocalGoodsColl', JSON.stringify(newGoodsColl))
    const res = {
      status: 200,
      mess: "お気に入りから削除しました"
    }
    resolve(res)
  })
}

export const isGoodsCollectd = (item) => {
  let localGoodsColl = localStorage.getItem('LocalGoodsColl');
  if (!localGoodsColl) {
    return 0
  } else {
    localGoodsColl = JSON.parse(localGoodsColl)
    let index = localGoodsColl.findIndex(goods => {
      return item.goods_id === goods.goods_id
    })
    if (index >= 0) {
      return 1
    } else {
      return 0
    }
  }
}

export const collGoodsList = () => {
  let localGoodsColl = localStorage.getItem('LocalGoodsColl')
  if (!localGoodsColl) {
    localGoodsColl = []
  } else {
    localGoodsColl = JSON.parse(localGoodsColl)
  }
  return localGoodsColl.map(item => {
    return item.goods_id
  })
}

export const addShopOrKOLFollowed = (data, type) => {
  return new Promise((resolve, reject) => {
    let localShopsOrKOLColl = "LocalShopsOrKOLColl"
    data.type = type
    let localShopsColl = localStorage.getItem(localShopsOrKOLColl);
    if (!localShopsColl) {
      localShopsColl = []
    } else {
      localShopsColl = JSON.parse(localShopsColl)
    }
    let index = localShopsColl.findIndex(item => {
      if (data.type === 'shop') {
        return data.shop_id === item.shop_id
      } else {
        return data.kol_id === item.kol_id
      }
    })
    if (index < 0) {
      localShopsColl.push(data)
    }
    localStorage.setItem(localShopsOrKOLColl, JSON.stringify(localShopsColl))
    const res = {
      status: 200,
      mess: "応援しました。"
    }
    resolve(res)
  })
}

export const removeShopOrKOLFollowed = (data, type) => {
  return new Promise((resolve, reject) => {
    let localKOLCollKey = "LocalShopsOrKOLColl"
    let localKOLColl = localStorage.getItem(localKOLCollKey);
    data.type = type
    if (!localKOLColl) {
      localKOLColl = []
    } else {
      localKOLColl = JSON.parse(localKOLColl)
    }
    let newShopsColl = localKOLColl.filter(oldItem => {
      return oldItem.type !== data.type || oldItem.kol_id !== data.kol_id
    })
    localStorage.setItem(localKOLCollKey, JSON.stringify(newShopsColl))
    const res = {
      status: 200,
      mess: "応援が取消しました"
    }
    resolve(res)
  })
}


export const isShopOrKOLFollowed = (item) => {
  let localShopsColl = localStorage.getItem('LocalShopsOrKOLColl');
  if (!localShopsColl) {
    return 0
  } else {
    localShopsColl = JSON.parse(localShopsColl)
    let index = localShopsColl.findIndex(shop => {
      if (item.type === "shop") {
        return item.type === shop.type && item.shop_id === shop.shop_id
      } else {
        return item.type === shop.type && item.kol_id === shop.kol_id
      }
    })
    if (index >= 0) {
      return 1
    } else {
      return 0
    }
  }
}

export const addToSubscribeCart = (item) => {
  return new Promise((resolve, reject) => {
    localStorage.setItem('LocalSubscribeCart', JSON.stringify(item))
    const res = {
      status: 200,
      mess: "カートに商品を追加しました。"
    }
    resolve(res)
  })
}

export const removeFromSubscribeCart = () => {
  localStorage.removeItem('LocalSubscribeCart')
}

export const getSubscribeCart = () => {
  // 获取本地购物车数量
  let localSubscribeCart = localStorage.getItem('LocalSubscribeCart');
  if (localSubscribeCart) {
    localSubscribeCart = JSON.parse(localSubscribeCart)
    return localSubscribeCart
  }
  return null
}

export const followShopOrKOLList = () => {
  let localShopsColl = localStorage.getItem('LocalShopsOrKOLColl')
  if (!localShopsColl) {
    localShopsColl = []
  } else {
    localShopsColl = JSON.parse(localShopsColl)
  }
  return localShopsColl.map(item => {
    if (item.type === "shop") {
      return { type: item.type, id: item.shop_id }
    } else {
      return { type: item.type, id: item.kol_id }
    }
  })
}
