<template>
  <div id="live-modale" class="background" :class="{ block: popup }">
    <div v-if="goods.data">
      <div id="add-cart-block" class="acddcart-active cart-block">
        <button
          type="button"
          id="add-cart-close"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="panelClose"
        ></button>
        <div class="add-cart-block-inner">
          <div class="add-cart-block-wrap">
            <div class="add-cart-img">
              <item-image
                :src="goods.data.goods_info.thumb_url"
                width="100%"
                :ratio="9 / 16"
              />
            </div>

            <h4>{{ goods.data.goods_info.goods_name }}</h4>
            <div class="add-cart-tags">
              <i class="icon-add-cart" v-if="goods.data.goods_info.discount_brief">{{
                goods.data.goods_info.discount_brief
              }}</i>
              <span class="comments-item-list-yen"
                >¥{{
                  $NumberToLocaleString(goods.data.goods_info.discount_price)
                }}<span>（税込）</span></span
              >
            </div>

            <form method="post" onsubmit="return false;">
              <GoodsSkuSelector
                :sku="sku"
                @selectedAttrs="selectedAttrs"
              ></GoodsSkuSelector>
              <div
                v-for="(option, index) in goods.data.goods_options"
                :key="index"
              >
                <div class="form-group">
                  <div class="option-price-info flex-row-start">
                    <input
                      class="option-check-input"
                      type="checkbox"
                      v-model="option.checked"
                      :disabled="option.is_required === 1"
                      v-if="option.type === 1"
                    />
                    <strong
                      >{{ option.name }}（{{ option.option_price != 0?
                        '¥' + $NumberToLocaleString(option.option_price) : '無料'
                      }}）</strong
                    >
                    <div v-if="option.is_required === 1" class="must">
                      *必須
                    </div>
                  </div>
                  <div v-if="option.type === 1">
                    <input
                      v-if="option.checked"
                      type="text"
                      required=""
                      v-model="option.option[0].value"
                      name="fname"
                      placeholder="入力してください"
                    />
                  </div>
                  <div v-else>
                    <div class="custom_select">
                      <select class="border p-10" v-model="option.value">
                        <option value="" disabled selected>
                          {{ option.name + 'をお選びください' }}
                        </option>
                        <option
                          :value="o.goods_option_id"
                          v-for="(o, index) in option.option"
                          :key="index"
                        >
                          {{ o.option_value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cart-box_volume">
                <span class="cart-box_volume_unit">購入数量</span>
                <div class="detail-qty">
                  <a @click="editCartNum(-1)" class="qty-down"
                    ><i class="fi-rs-angle-small-down"></i
                  ></a>
                  <span class="qty-val">{{ cartNum }}</span>
                  <a @click="editCartNum(1)" class="qty-up"
                    ><i class="fi-rs-angle-small-up"></i
                  ></a>
                </div>
              </div>
              <div class="cart-box_volume mt-10" v-if="isSubscribe">
                こちらの商品は最低{{ goods.data.goods_info.subscribe_min_times }}回以上のお届けとなっております。<br>
                {{ goods.data.goods_info.subscribe_min_times }}回以上のご購入のち、解約されたい方は別途マイページより解約のお手続きをお願いします。
              </div>
              <div class="cart-box_volume hidden" v-if="isSubscribe">
                <span class="cart-box_volume_unit">購入回数</span>
                <div>
<!--                  <div-->
<!--                    v-if="goods.data.goods_info.subscribe_max_times === 0"-->
<!--                  >-->
<!--                    <div class="flex-row-start">-->
<!--                      <label>-->
<!--                         <input-->
<!--                            class="option-check-input"-->
<!--                            style="display:inline-block"-->
<!--                            type="checkbox"-->
<!--                            :checked="subscribeUnlimited"-->
<!--                            v-model="subscribeUnlimited"-->
<!--                          />-->
<!--                          <span-->
<!--                            class="cart-box_volume_unit"-->
<!--                            style="white-space: nowrap"-->
<!--                            >無制限</span-->
<!--                          >-->
<!--                      </label>-->

<!--                    </div>-->
<!--                  </div>-->
                  <div class="detail-qty" v-if="!subscribeUnlimited">
                    <a @click="editSubscribeNum(-1)" class="qty-down"
                      ><i class="fi-rs-angle-small-down"></i
                    ></a>
                    <span class="qty-val">{{ subscribeNum }}</span>
                    <a @click="editSubscribeNum(1)" class="qty-up"
                      ><i class="fi-rs-angle-small-up"></i
                    ></a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="add-cart-btn">
          <GoodsPrice
            :discount="goods.data.goods_info.discount_price"
            :original="goods.data.goods_info.shop_price"
            :subscribe="goods.data.goods_info.is_subscribe"
            :goodsNumber="cartNum"
          ></GoodsPrice>

          <button
            type="submit"
            class="button button-add-to-buy"
            @click="addToCart"
            v-if="isSubscribe"
          >
            今すぐ購入
          </button>
          <a
            class="btn"
            @click="addToCart"
            v-else-if="goods.data.sku_number > 0"
            >カートに追加</a
          >
          <button type="submit" class="button btn-no-select" v-else disabled>
            売り切れ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { computed, nextTick, watch } from '@vue/runtime-core'
import { toRefs } from 'vue'
import { authApi } from '@/api'
import GoodsPrice from '@/components/shop/goods/GoodsPrice.vue'
import ItemImage from '@/components/ItemImage.vue'
import GoodsSkuSelector from '@/components/shop/goods/GoodsAttrSelector.vue'
import { addToSubscribeCart } from '@/utils/locally'
import { getGoodsAttrIDStr } from '@/utils/utils'
import store from '@/store'

export default {
  name: 'GoodsSkuPanel',
  emits: ['addedToCart','onSelectedAttrs','update:popup'],
  components: { GoodsSkuSelector, ItemImage, GoodsPrice },
  props: {
    popup: {
      type: Boolean,
      require: true,
      default: false,
    },
    goods_id: {
      type: Number,
      require: true,
      default: null,
    },
    kolId:{
      default: null,
    },
    attrs : {
    },
  },
  setup(props, { emit }) {
    const { popup } = toRefs(props)
    watch(popup, (newValue) => {
      console.log('popup', popup)
      if (newValue) {
        getGoodsDetail()
      }
    })

    const goods = reactive({
      data: null,
    })
    const sku = ref(null)
    const getGoodsDetail = () => {
      authApi
        .post('api/Goods/goodsInfo', {
          goods_id: props.goods_id,
        })
        .then((res) => {
          sku.value = res.data.goods_attrs
          goods.data = res.data
          subscribeNum.value = goods.data.goods_info.subscribe_min_times || 1
          goods.data.goods_options.forEach((option) => {
            if (option.type === 1) {
              option.value = null
              option.checked = option.is_required === 1
            }
          })
          subscribeUnlimited.value =　true
            // goods.data.goods_info.subscribe_max_times === 0
          console.log(goods.data)
          if (props.attrs?.length > 0) {
            selectedAttrs(props.attrs)
          }
        }).catch(()=>{
          panelClose()
        })
    }
    const isSubscribe = computed(() => {
      return goods.data.goods_info.is_subscribe === 1
    })

    const subscribeNum = ref(1)

    const subscribeUnlimited = ref(true)

    const panelClose = () => {
      emit('update:popup', false)
    }

    const cartNum = ref(1)
    const editCartNum = (num) => {
      const number = cartNum.value + num
      const maxNumber = isSubscribe.value ? 9 : goods.data.sku_number
      if (number === 0 || number > maxNumber) {
        return
      }
      cartNum.value = number
    }

    const selectedAttrs = (attrs) => {
      authApi
        .post('api/Goods/goodsSkuInfo', {
          goods_id: props.goods_id,
          sku_attrs: attrs.join(),
        })
        .then((res) => {
          goods.data.goods_info.discount_brief = res.data.goods_info.discount_brief
          goods.data.goods_info.discount_price = res.data.goods_info.discount_price
          goods.data.goods_info.shop_price = res.data.goods_info.shop_price
          goods.data.sku_number = res.data.sku.goods_number
          sku.value.forEach((item, i) => {
            item.data.forEach((attr, j) => {
              res.data.goods_attrs[i].data[j].checked = attr.checked
            })
          })
          goods.data.goods_info.goods_attrs = res.data.goods_attrs
          sku.value = res.data.goods_attrs
          emit('onSelectedAttrs', attrs)
          console.log(goods.data)
        })
    }

    const selectGoodsOption = (e) => {
      console.log(e)
    }

    const editSubscribeNum = (num) => {
      const number = subscribeNum.value + num
      if (number < goods.data.goods_info.subscribe_min_times || (goods.data.goods_info.subscribe_max_times !== 0 && number > goods.data.goods_info.subscribe_max_times)) {
        return
      }

      subscribeNum.value = number
    }
    const addToCart = () => {
      console.log(goods.data.goods_options)

      const attrIDStr = getGoodsAttrIDStr(sku.value)

      let optionIdArray = []
      for (let option of goods.data.goods_options) {
        let item = option.option.find((e) => {
          if (option.type === 1) {
            return e.value
          }
          return (e.goods_option_id === option.value)
        })
        if (item) {
          if (option.type === 1) {
            if (option.checked) {
              optionIdArray.push({
                id: item.goods_option_id,
                value: item.value,
              })
            }
          } else {
            optionIdArray.push({
              id: item.goods_option_id,
              value: item.option_value,
            })
          }
        } else {
          if (option.is_required === 1 || option.checked) {
            let message =
              option.type === 1 ? 'を入力してください' : 'をお選びください'
            alert(option.name + message)
            return
          }
        }
      }
      const params = {
        goods_id: props.goods_id,
        num: cartNum.value,
        goods_attr: attrIDStr,
        kol_id: props.kolId ? Number(props.kolId) : null
      }
      if (optionIdArray.length > 0) {
        params.goods_options = optionIdArray
      }
      if (isSubscribe.value) {
        params.times = subscribeUnlimited.value ? 0 : subscribeNum.value
        authApi
          .post('api/Subscribe/checkSubscribeOrder', params)
          .then((res) => {
            addToSubscribeCart(params).then((res) => {
              emit('addedToCart',isSubscribe.value)
            })
          })
      } else {
        authApi.addToCart(params).then((res) => {
          console.log(res)
          if (res.data?.status === 200 || res.status === 200) {
            alert(res.mess, '', 'success')
          }
          store.dispatch('updateCartList')
          emit('addedToCart')
        })
      }
    }
    return {
      sku,
      goods,
      panelClose,
      cartNum,
      editCartNum,
      isSubscribe,
      subscribeNum,
      editSubscribeNum,
      addToCart,
      selectedAttrs,
      selectGoodsOption,
      subscribeUnlimited,
    }
  },
}
</script>

<style scoped>
.block {
  display: block !important;
}

.cart-block {
  display: block !important;
  background: #fff;
}

.background {
  background: #3333338f;
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 90;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: start;
}

.option-price-info {
  font-size: 13px;
}

.option-check-input {
  position: relative;
  top: 3px;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background: #ddd;
  border-radius: 100%;
  margin-right: 5px;
}

.sku-active {
}

.must {
  color: #ff3232;
}
</style>
