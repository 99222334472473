import { createRouter, createWebHistory } from 'vue-router'
import { setSEO } from "@/utils/utils";
import { event } from "vue-gtag";
const routes = [
  {
    path: '/shop',
    name: 'ShopIndex',
    redirect: { name: 'Home' },
    component: () => import(/* webpackChunkName: "ShopIndex" */ '../views/shop/Index.vue'),
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          title: 'SharingLive - ライブコマースで日本地方を元気にする',
          description: 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」',
          keywords: 'SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物,ライブ,ライブコマース,ライブ配信'
        },
        component: () =>
          import(
            /* webpackChunkName: "Home" */ '../views/shop/home/Home.vue'
          )
      },
      {
        path: "/login",
        name: "Login",
        meta: {
          isAccountPage: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Login" */ '../views/shop/account/Login.vue'
          )
      },
      {
        path: "/login/mail",
        name: "LoginMail",
        meta: {
          isAccountPage: true
        },
        component: () =>
          import(
            /* webpackChunkName: "LoginMail" */ '../views/shop/account/LoginMail.vue'
          )
      },
      {
        path: "/sns_login",
        name: "LoginSNS",
        meta: {
          isAccountPage: true
        },
        component: () =>
          import(
            /* webpackChunkName: "LoginSNS" */ '../views/shop/account/LoginSNS.vue'
          )
      },
      {
        path: "/register",
        name: "Register",
        meta: {
          isAccountPage: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Register" */ '../views/shop/account/Register.vue'
          )
      },
      {
        path: "/register/mail",
        name: "RegisterMail",
        meta: {
          isAccountPage: true
        },
        component: () =>
          import(
            /* webpackChunkName: "RegisterMail" */ '../views/shop/account/RegisterMail.vue'
          )
      },
      {
        path: "/reset/mail",
        name: "ResetPasswordMail",
        meta: {
          isAccountPage: true
        },
        component: () =>
          import(
            /* webpackChunkName: "ResetPasswordMail" */ '../views/shop/account/ResetPasswordMail.vue'
          )
      },
      {
        path: "/shop/list",
        name: "ShopList",
        meta: {
          title: '店舗一覧',
          description: 'SharingLiveに出店している店舗の一覧',
          keywords: '店舗一覧,SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物,ライブ,ライブコマース,ライブ配信'
        },
        component: () =>
          import(
            /* webpackChunkName: "ShopList" */ '../views/shop/shop/ShopList.vue'
          )
      },
      {
        path: "/shop/detail/:id",
        name: "ShopDetail",
        component: () =>
          import(
            /* webpackChunkName: "ShopDetail" */ '../views/shop/shop/ShopDetail.vue'
          )
      },
      {
        path: "/product/list/:type/:id?/:proId?",
        name: "ProductList",
        meta: {
          title: '商品一覧',
          description: 'SharingLiveに販売している商品の一覧',
          keywords: '商品一覧,SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物,ライブ,ライブコマース,ライブ配信'
        },
        component: () =>
          import(
            /* webpackChunkName: "ProductList" */ '../views/shop/product/ProductList.vue'
          )
      },
      // ProductDetail
      {
        path: "/product/detail/:id",
        name: "ProductDetail",
        component: () =>
          import(
            /* webpackChunkName: "ProductDetail" */ '../views/shop/product/ProductDetail.vue'
          )
      },
      {
        path: "/kol/list",
        name: "KolList",
        meta: {
          title: 'KOL（ライバー）一覧',
          description: 'SharingLiveに登録するライバーの一覧',
          keywords: 'KOL（ライバー）一覧,SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物,ライブ,ライブコマース,ライブ配信'
        },
        component: () =>
          import(
            /* webpackChunkName: "KolList" */ '../views/shop/kol/KolList.vue'
          )
      },
      {
        path: "/kol/detail/:id",
        name: "KolDetail",
        component: () =>
          import(
            /* webpackChunkName: "KolDetail" */ '../views/shop/kol/KolDetail.vue'
          )
      },
      {
        path: "/live/list",
        name: "LiveList",
        meta: {
          title: 'ライブ配信一覧',
          description: 'SharingLive商品販売と伴いライブ配信の一覧',
          keywords: 'ライブ,ライブコマース,ライブ配信,SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物'
        },
        component: () =>
          import(
            /* webpackChunkName: "LiveList" */ '../views/shop/live/LiveList.vue'
          )
      },
      {
        path: "/live/search/:keyword",
        name: "LiveSearch",
        meta: {
          title: '配信検索 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '配信検索、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: ''
        },
        component: () =>
          import(
            /* webpackChunkName: "LiveSearch" */ '../views/shop/live/LiveSearch.vue'
          )
      },
      {
        path: "/live/main/:shop_id/:is_limit",
        name: "LiveMain",
        component: () =>
          import(
            /* webpackChunkName: "LiveMain" */ '../views/shop/live/LiveMain.vue'
          )
      },
      {
        path: "/search/home",
        name: "ProductSearchHome",
        component: () =>
          import(
            /* webpackChunkName: "ProductSearchHome" */ '../views/shop/product/ProductSearchHome.vue'
          )
      },
      {
        path: "/article/:tag",
        name: "ArticleDetail",
        component: () =>
          import(
            /* webpackChunkName: "ArticleDetail" */ '../views/shop/article/Detail.vue'
          )
      },
      {
        path: "/cart",
        name: "Cart",
        meta: {
          title: 'カート | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: 'カート、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: ''
        },
        component: () =>
          import(
            /* webpackChunkName: "Cart" */ '../views/shop/cart/Cart.vue'
          )
      },
    ]
  },
  {
    path: '/user',
    name: 'userIndex',
    redirect: { name: 'Dashboard' },
    component: () => import(/* webpackChunkName: "userIndex" */ '../views/user/Index.vue'),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          title: 'ダッシュボード | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: 'ダッシュボード、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ '../views/user/dashboard/Dashboard.vue'
          )
      },
      {
        path: "message/list",
        name: "MessageList",
        meta: {
          title: 'メッセージ一覧 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: 'メッセージ一覧、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "MessageList" */ '../views/user/message/MessageList.vue'
          )
      },
      {
        path: "message/detail/:id",
        name: "MessageDetail",
        meta: {
          title: 'メッセージ | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: 'メッセージ、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "MessageDetail" */ '../views/user/message/MessageDetail.vue'
          )
      },
      {
        path: "order/list",
        name: "OrderList",
        meta: {
          title: '注文履歴一覧 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '注文履歴一覧、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "OrderList" */ '../views/user/order/OrderList.vue'
          )
      },
      {
        path: "order/list/:id",
        name: "OrderDetail",
        meta: {
          title: '注文詳細 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '注文詳細、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "OrderDetail" */ '../views/user/order/OrderDetail.vue'
          )
      },
      {
        path: "order/list/subscribe/:id",
        name: "OrderSubscribeDetail",
        meta: {
          title: '定期便注文詳細 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '定期便注文詳細、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "OrderSubscribeDetail" */ '../views/user/order/OrderSubscribeDetail.vue'
          )
      },
      {
        path: "profile/edit",
        name: "ProfileEdit",
        meta: {
          title: 'プロファイル編集 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: 'プロファイル編集、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ '../views/user/profile/ProfileEdit.vue'
          )
      },
      {
        path: "address/list",
        name: "AddressList",
        meta: {
          title: '届き先一覧 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '届き先一覧、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AddressList" */ '../views/user/address/AddressList.vue'
          )
      },
      {
        path: "address/add",
        name: "AddressAdd",
        meta: {
          title: '届き先編集 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '届き先編集、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AddressEditor" */ '../views/user/address/AddressEditor.vue'
          )
      },
      {
        path: "address/edit/:id",
        name: "AddressEdit",
        meta: {
          title: '届き先編集 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '届き先編集、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AddressEditor" */ '../views/user/address/AddressEditor.vue'
          )
      },
      {
        path: "delete/account",
        name: "DeleteAccount",
        meta: {
          title: '届き先一覧 | ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          description: '届き先一覧、ライブコマースで日本地方を元気にするアプリケーション「SharingLiveAPP」',
          keywords: '',
          shouldLogin: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AddressList" */ '../views/user/deleteAccount/DeleteAccount.vue'
          )
      },
    ]
  },
  {
    // join
    path: "/join",
    name: "Join",
    meta: {
      shouldLogin: false,
    },
    component: () =>
      import(/* webpackChunkName: "Join" */ '../views/join/Join.vue')
  },
  {
    // join
    path: "/join/agreement",
    name: "JoinAgreement",
    meta: {
      shouldLogin: false,
    },
    component: () =>
        import(/* webpackChunkName: "Join" */ '../views/join/JoinAgreement.vue')
  },
  {
    // join
    path: "/join/success",
    name: "JoinSuccess",
    meta: {
      shouldLogin: false,
    },
    component: () =>
      import(/* webpackChunkName: "JoinSuccess" */ '../views/join/JoinSuccess.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log('to', to)
  console.log('from', from)
  if (to.name === 'Login' && from.name && from.meta?.isAccountPage !== true) {
    const fromRoute = {
      name: from.name,
      params: from.params,
      query: from.query,
    }
    sessionStorage.setItem('loginFromRoute', JSON.stringify(fromRoute))
  }
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 50)
  Swal.close()
  next()
})
router.afterEach((to, from) => {
  // document.getElementById('shopBody').scrollTop = 0
  const SEO = {
    title: 'SharingLive - ライブコマースで日本地方を元気にする',
    description: 'ライブコマースで「見つけよう！第2のふるさと」。日本地方を元気にするアプリケーション「SharingLive APP」',
    keywords: 'SharingLive,ふるさと納税, 産直,特産品,地方創生,お土産,ギフト,プレゼント,贈り物,ライブ,ライブコマース,ライブ配信'
  }
  const toMeta = to.meta || {}
  Object.keys(SEO).forEach((key) => {
    SEO[key] = toMeta[key] || SEO[key]
  })
  setSEO({
    ogUrl: window.location.href,
    ogTitle: SEO.title,
    ogDescription: SEO.description,
    ogSiteName: SEO.title,
    ogImage: `${window.location.origin}/assets/imgs/defaultImg.png`,
    twitterDescription: SEO.description,
    twitterImage: `${window.location.origin}/assets/imgs/defaultImg.png`,
    twitterTitle: SEO.title,

    siteKeywords: SEO.keywords,
    siteDescription: SEO.description,
    siteTitle: SEO.title,
  })
  setTimeout(() => {
    event('conversion', { 'send_to': 'AW-1004513208/l0ziCJL-4Y8YELjP_t4D' })
  }, 1000)

})
export default router
