import { createAPI } from './create-api'
import auth from './module/auth'
import router from '@/router'

const api = createAPI({ baseURL: '/api' })
//请求拦截器
api.interceptors.request.use(
    (config) => {
        console.log(config)
        config.params = {
            t: `${new Date().getTime()}`,
            ...config.params
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
  (response) =>{
    const status = response.data?.status
    const mess = response.data?.mess
    switch (status) {
      case 400:
        if (mess === '端末情報が取得できません。ログインしてください。') {
          router.replace({
            name:'Login'
          })
          setTimeout(()=>{
            alert(mess,'','error')
          },1000)
        }
        else if (mess) {
          alert(mess,'','error')
        }
        throw response.data;
    
      default:
        break;
    }
    return response.data;
  },
  (error)=>{
    return Promise.reject(error);
  }
)

export const authApi = auth(api)
