<template>
  <span>{{ countdownText }}</span>
</template>
<script>
import { computed } from '@vue/runtime-core'
import store from '@/store'

export default {
  props: {
    time: {},
  },
  setup(props) {
    const nowCom = computed(() => {
      return store.getters.nowTimeDate
    })
    const countdownText = computed(() => {
      const close = Date.parse(props.time.replace(/-/g, '/'))
      const now = Date.parse(nowCom.value)
      const msec = close - now
      if (msec > 0) {
        var hour = formatNumber(parseInt(msec / 1000 / 60 / 60))
        var min = formatNumber(parseInt((msec / 1000 / 60) % 60))
        var sec = formatNumber(parseInt((msec / 1000) % 60))
        return hour + ':' + min + ':' + sec
      } else {
        return '00:00:00'
      }
    })
    const formatNumber = (num) => {
      num = num.toString()
      return num[1] ? num : '0' + num
    }
    return {
      countdownText,
    }
  },
}
</script>
<style></style>
