<template>
  <div :class="{ horizon: liveInfo.orientation === 1 }" class="live-mov-body">
    <div id="live-mov" v-if="getLiveType === 1">
      <!-- pcdisplay -->
      <!-- spdisplay -->
    </div>
    <div id="live-mov" v-else>
      <!-- pcdisplay -->
      <!-- spdisplay -->
      <video
        controls
        autoplay
        playsinline
        :src="liveInfo.videos[playIndex]?.video_url"
        ref="videoEl"
        :poster="liveInfo.cover"
        disablePictureInPicture
        controlslist="nofullscreen"
      >
        <!-- <source src="assets/dummy-mov/dummy-live.mp4" type="video/mp4"> -->
        <p>このブラウザーは HTML5 video に対応していません。</p>
      </video>
      <span style="width: 100px; height: 40px; position: absolute; top: 0; left: 0"></span>
    </div>
  </div>
</template>
<script>
import { TcPlayer } from '@/assets/TcPlayer-module-2.4.1'
import { reactive, ref } from '@vue/reactivity'
import { computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, } from '@vue/runtime-core'
import store from '@/store'
import { authApi } from '@/api'

export default {
  setup() {
    const liveInfo = computed(() => {
      return store.getters.liveData
    })
    const isLogin = computed(() => {
      return !!store.getters.token
    })
    // 获取直播类型
    const getLiveType = computed(() => {
      // 0普通，1直播 2录播
      let live_type = 0
      if (liveInfo.value.pull_link && liveInfo.value.pull_link != '') {
        live_type = 1
      } else if (liveInfo.value.videos && liveInfo.value.videos.length > 0) {
        live_type = 2
      }
      return live_type
    })
    let player = null
    const initPlayer = () => {
      player = new TcPlayer('live-mov', {
        m3u8: liveInfo.value.pull_link,
        volume: 0.8,
        flash: false,
        autoplay: true,
        width: '100%',
        height: '100%',
        poster: liveInfo.value.cover,
        controls: 'system',
        wording: {
          1: "ストリーミング配信が未開始",
          2: "ストリーミング配信が未開始",
          3: "ストリーミング配信が未開始",
          4: "配信のフォーマットがサポートしません、他のブラウザやシステムを試してください。",
          5: "配信のフォーマットがサポートしません、他のブラウザやシステムを試してください。",
          10: "エラー、管理者にお問い合わせください。",
          11: "エラー、管理者にお問い合わせください。",
          12: "エラー、管理者にお問い合わせください。",
          13: "ストリーミング配信が未開始。",
          1001: "ストリーミング配信が未開始。",
          1002: "ストリーミング配信が未開始。",
          2001: "エラー、管理者にお問い合わせください。",
          2002: "エラー、管理者にお問い合わせください。",
          2003: "エラー、管理者にお問い合わせください。",
          2032: "ストリーミング配信が未開始。",
          2048: "ストリーミング配信が未開始。"
        },
        listener: function (msg) {
          if (msg.type === 'error') {
            document.getElementsByClassName('vcp-error-tips')[0].attributes['style'].value = "display:none"
          }
        }
      })
    }

    const playIndex = ref(0)
    const videoEl = ref()
    const init = () => {
      if (getLiveType.value === 1) {
        initPlayer()
      } else {
        updateVideoProgress()
        nextTick(() => {
          playIndex.value = 0
          const playId = videoProgress.play_id
          const nowIndex = liveInfo.value.videos.findIndex((item) => {
            return Number(item.id) === Number(playId)
          })
          if (nowIndex !== -1) {
            playIndex.value = nowIndex
          }
          if (videoProgress.play_time) {
            videoEl.value.currentTime = Number(videoProgress.play_time)
          }

          videoEl.value.onended = (event) => {
            const nextIndex = playIndex.value + 1
            if (nextIndex + 1 > liveInfo.value.videos.length) {
              playIndex.value = 0
            } else {
              playIndex.value = nextIndex
            }
            videoProgress.play_id = liveInfo.value.videos[playIndex.value].id
          }
        })
      }
    }
    const destroy = () => {
      playIndex.value = 0
      if (player) {
        player.destroy()
        player = null
      }
    }

    const videoProgress = reactive({
      play_id: null,
      play_time: 0,
    })

    const updateVideoProgress = () => {
      var progress = null
      let key = 'VideoProgress' + liveInfo.value.id
      let progressString = localStorage.getItem(key)
      if (progressString) {
        try {
          progress = JSON.parse(progressString)
        } catch (error) {
        }
      }
      if (liveInfo.value.play_progress) {
        progress = {
          play_id: liveInfo.value.play_progress?.play_id || null,
          play_time: liveInfo.value.play_progress?.play_time || 0,
        }
      }
      if (progress) {
        videoProgress.play_id = progress.play_id
        videoProgress.play_time = progress.play_time
      }
    }
    const saveVideoProgress = () => {
      if (getLiveType.value === 2) {
        videoProgress.play_time = videoEl.value.currentTime || 0
        videoProgress.play_time = Math.floor(videoProgress.play_time)
        let progress = JSON.parse(JSON.stringify(videoProgress))
        progress.play_time = String(progress.play_time)
        progress.play_id = String(progress.play_id)
        if (progress) {
          if (isLogin.value) {
            var params = {
              schedule_id: liveInfo.value.id,
              progress: progress,
            }
            authApi.aliveScheduleAliveStatus(params)
            liveInfo.value.play_progress = progress
          } else {
            let key = 'VideoProgress' + liveInfo.value.id
            localStorage.setItem(key, JSON.stringify(progress))
          }
        }
      }
    }

    const playVideo = (index) => {
      playIndex.value = index
      videoProgress.play_id = liveInfo.value.videos[playIndex.value].id
    }

    onMounted(() => {
      init()
      window.addEventListener('beforeunload', saveVideoProgress)
    })
    onBeforeUnmount(() => {
      saveVideoProgress()
    })
    onUnmounted(() => {
      destroy()
      window.removeEventListener('beforeunload', saveVideoProgress)
    })
    return {
      liveInfo,
      getLiveType,
      playIndex,
      videoEl,
      playVideo,
    }
  },
}
</script>
<style>
/* .live-modale-body.size-small #live-mov {
  top: unset !important;
  bottom: -119px;
  height: auto !important;
}
.live-modale-body.size-small video {
  height: auto !important;
} */
.live-modale-body.size-small .live-mov-body {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
