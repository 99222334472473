<template>
  <router-view />
  <LiveModale ref="LiveModaleRef" />
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/runtime-core'
import store from '@/store'
import LiveModale from '@/components/live/LiveModale.vue'
import { useRouter, useRoute } from 'vue-router'

export default {
  components: { LiveModale },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const LiveModaleRef = ref()

    watch(route, (val) => {
      var $body = $('body')
      console.log(LiveModaleRef.value)
      if (val.name !== 'LiveMain' && $body.hasClass('modal-active')) {
        // LiveModaleRef.value.closeModal()
        // size-small
        $('#live-modale').addClass('size-small')
        $('body').addClass('small-video')
      } else {
        $('#live-modale').removeClass('size-small')
        $('body').removeClass('small-video')
      }
    })
    onMounted(() => {
      $(window).on('load', function () {
        $('#preloader-active').delay(450).fadeOut('slow')
        $('body').delay(450).css({
          overflow: 'visible',
        })
        $('#onloadModal').modal('show')
      })
      const token = localStorage.getItem('token')
      const LiveModaleRef = ref()
      if (token) {
        store.dispatch('setUserInfo')
      }
      store.dispatch('startNowTimeDate')
    })
    return {
      LiveModaleRef,
    }
  },
}
</script>

<style></style>
