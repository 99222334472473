import { md5 } from '@/utils/md5'
import { uuid } from '@/utils/utils'
import {
  addGoodsCollectd, addShopOrKOLFollowed,
  addToCart, collGoodsList, followShopOrKOLList,
  removeFromCart,
  removeGoodsCollectd, removeShopOrKOLFollowed
} from "@/utils/locally";
import store from "@/store";
import {authApi} from "@/api";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const formartDate = () => {
  const timezone = 9; //目标时区时间，东九区
  const offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  const date = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
  const year = date.getFullYear()
  const month = parseInt(date.getMonth()) + 1 > 9 ? parseInt(date.getMonth()) + 1 : '0' + (parseInt(date.getMonth()) + 1)
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()

  return year + '-' + month + '-' + day
}
const sumApiToken = (url) => {
  return md5(
    `${url}${formartDate()}${process.env.VUE_APP_SECRETKEY}`
  )
}

const apiAuthSum = (url) => {
  let device_token = localStorage.getItem('device_token')
  if (!device_token) {
    let newUUID = `web_${uuid()}`
    newUUID = newUUID.replace(/-/g, '')
    localStorage.setItem('device_token', newUUID)
    device_token = newUUID
  }
  const token = localStorage.getItem('token')
  const res = {
    client_id: Number(process.env.VUE_APP_CLIENT_ID),
    api_token: sumApiToken(url),
    devicetoken: device_token
  }
  if (token) {
    res['token'] = token
  }
  return res
}

export default function (api) {
  const post = (url, data) => {
    return api.post(`${apiBaseUrl}/${url}`, {
      ...apiAuthSum(url),
      ...data
    })
  }
  return {
    post,

    // home
    homeInfo (data) {
      const url = 'api/Home/homeInfo'
      return api.post(`${apiBaseUrl}/${url}`, {
        ...apiAuthSum(url),
        ...data
      })
    },
    menuInfo (data) {
      const url = 'api/Home/getMenuInfo'
      return api.post(`${apiBaseUrl}/${url}`, {
        ...apiAuthSum(url),
        ...data
      })
    },
    searchInfo (data) {
      const url = 'api/Search/index'
      return api.post(`${apiBaseUrl}/${url}`, {
        ...apiAuthSum(url),
        ...data
      })
    },
    //获取首页直播一览
    getHomeNewAliveList (data) {
      return post('api/Home/getNewAliveList', data)
    },
    //添加到购物车
    addToCart (data) {
      localStorage.removeItem('AgeCheck')
      if (store.getters.token) {
        return post('api/Cart/addcart', data)
      } else {
        return addToCart(data)
      }
    },
    //从购物车删除
    removeFromCart (data) {
      if (store.getters.token) {
        return post('api/Cart/delcart', data)
      } else {
        return removeFromCart(data)
      }
    },
    editCart (data) {
      const url = store.getters.token ? 'api/Cart/editcart' : 'api/Cart/editCartLocal'
      return post(url, data)
    },
    uploadFile (data) {
      const url = 'api/Common/uploadPic'
      const formData = new FormData()
      const authData = apiAuthSum(url)
      Object.keys(authData).forEach((key) => {
        formData.append(key, authData[key])
      })
      Object.keys(data).forEach((key) => {
        if (key === 'file') {
          formData.append(key, data[key], 'img.png')
        } else {
          formData.append(key, data[key])
        }

      })
      return api.post(`${apiBaseUrl}/${url}`, formData)
    },
    collectGoods (data) {
      if (store.getters.token) {
        return post('api/CollGoods/coll', data)
      } else {
        return addGoodsCollectd(data)
      }
    },
    unCollectGoods (data) {
      if (store.getters.token) {
        return post('api/CollGoods/cancelcoll', data)
      } else {
        return removeGoodsCollectd(data)
      }
    },
    collectGoodsList (data) {
      if (store.getters.token) {
        return post('api/MemberColl/getCollGoodsList', data)
      } else {
        let localGoodsColl = collGoodsList();
        let pageSize = 10
        let offset = (data.page - 1) * pageSize;
        let newArr = (offset + pageSize >= localGoodsColl.length) ? localGoodsColl.slice(offset, localGoodsColl.length) : localGoodsColl.slice(offset, offset + pageSize);
        return post('api/MemberColl/getLocalCollGoodsList', { "goods_list": newArr })
      }
    },
    collectShop (data) {
      if (store.getters.token) {
        return post('api/CollShops/coll', data)
      } else {
        return addShopOrKOLFollowed(data, 'shop')
      }
    },
    unCollectShop (data) {
      if (store.getters.token) {
        return post('api/CollShops/cancelcoll', data)
      } else {
        return removeShopOrKOLFollowed(data, 'shop')
      }
    },
    collectKOL (data) {
      if (store.getters.token) {
        return post('api/CollKol/coll', data)
      } else {
        return addShopOrKOLFollowed(data, 'kol')
      }
    },
    unCollectKOL (data) {
      if (store.getters.token) {
        return post('api/CollKol/cancelcoll', data)
      } else {
        return removeShopOrKOLFollowed(data, 'kol')
      }
    },
    collectShopOrKOLList (data) {

      if (store.getters.token) {
        return post('api/v2/MemberColl/getFollowChannelList', data)
      } else {
        let localShopColl = followShopOrKOLList();
        let pageSize = 10
        let offset = (data.page - 1) * pageSize;
        let newArr = (offset + pageSize >= localShopColl.length) ? localShopColl.slice(offset, localShopColl.length) : localShopColl.slice(offset, offset + pageSize);
        return post('api/v2/MemberColl/getLocalFollowChannelList', { "channel_list": newArr })
      }
    },
    getKOLList (data) {
      return post("api/Kol/kolList", data);
    },
    getKOLInfo (data) {
      return post("api/Kol/kolInfo", data);
    },
    /**
     * KOL取得视频列表
     *
     * @returns
     */
    kolGetVideoList (data) {
      return post("api/Kol/getVideoList", data);
    },
    /**
     * KOL取得直播列表
     *
     * @returns
     */
    kolGetAliveList (data) {
      return post("api/Kol/getAliveList", data);
    },
    /**
     * KOL商品列表
     *
     * @returns
     */
    kolGetGoodsList (data) {
      return post("api/Kol/getGoodsList", data);
    },
    /**
   * 检索直播
    *
    * @param {any} data
    * @returns
    */
    getsearchAlive (data) {
      return post("api/Search/searchAlive", data);
    },
    /**
   * 进入直播房间
   *
   * @returns
   */
    aliveScheduleAliveEnterRoom (data) {
      return post("api/AliveSchedule/enterRoom", data);
    },
    /**
   * 获取直播状态
   *
   * @returns
   */
    aliveScheduleAliveStatus (data) {
      return post("api/AliveSchedule/getLiveStatus", data);
    },
    /**
   * 获取历史聊天记录
   *
   * @returns
   */
    aliveScheduleAliveChatHistory (data) {
      return post("api/AliveSchedule/getChatHistory", data);
    },
    /**
   * 提交问卷答案
   *
   * @returns
   */
    questionnaireAnswerQuestionnairer (data) {
      return post("api/Questionnaire/answerQuestionnaire", data)
    },
    /**
   * 参加抽奖
   *
   * @returns
   */
    getEntryDraw (data) {
      return post("api/AliveSchedule/entryDraw", data);
    },
    /**
   * 收货地址列表接口
   *
   * @param {any} data
   * @returns
   */
    getAddressList (data) {
      return post("api/Address/index", data);
    },
    /**
   * 领取奖品
   *
   * @returns
   */
    takePrize (data) {
      return post("api/AliveSchedule/takePrize", data);
    },
    /**
   * 取消关注KOL接口
   *
   * @param {any} data
   * @returns
   */
    getKOLCancelColl (data) {
      if (store.getters.token) {
        return post("api/CollKol/cancelcoll", data);
      } else {
        return new Promise((resolve, reject) => {
          let localKOLCollKey = "LocalShopsOrKOLColl"
          var localKOLColl = localStorage.getItem(localKOLCollKey)
          data.type = "kol"
          if (!localKOLColl) {
            localKOLColl = []
          }
          let newShopsColl = localKOLColl.filter(oldItem => {
            return oldItem.type != data.type || oldItem.kol_id != data.kol_id
          })
          try {
            localStorage.setItem(localKOLCollKey, newShopsColl)
            const res = {
              status: 200,
              mess: "応援が取消しました"
            }
            resolve(res)
          } catch (e) {
            const res = {
              status: 400,
              mess: ""
            }
            reject(res)
          }
        })
      }
    },
    /**
   * 关注KOL接口
   *
   * @param {any} data
   * @returns
   */
    getKOLColl (data) {

      if (store.getters.token) {
        return post("api/CollKol/coll", data);
      } else {
        return new Promise((resolve, reject) => {
          let localShopsOrKOLColl = "LocalShopsOrKOLColl"
          data.type = "kol"
          var localShopsColl = localStorage.getItem(localShopsOrKOLColl)
          if (!localShopsColl) {
            localShopsColl = []
          }
          let index = localShopsColl.findIndex(item => {
            return data.kol_id == item.kol_id
          })
          if (index < 0) {
            localShopsColl.push(data)
          }
          try {
            localShopsOrKOLColl.setItem(localShopsOrKOLColl, localShopsColl)
            const res = {
              status: 200,
              mess: "応援しました。"
            }
            resolve(res)
          } catch (e) {
            const res = {
              status: 400,
              mess: ""
            }
            reject(res)
          }
        })
      }
    },
    /**
   * 取消关注商家接口
   *
   * @param {any} data
   * @returns
   */
    getshopcancelcoll (data) {
      if (store.getters.token) {
        return post("api/CollShops/cancelcoll", data);
      } else {
        return new Promise((resolve, reject) => {
          data.type = "shop"
          let localShopsOrKOLColl = "LocalShopsOrKOLColl"
          var localShopsColl = localStorage.getItem(localShopsOrKOLColl)
          if (!localShopsColl) {
            localShopsColl = []
          }
          let newShopsColl = localShopsColl.filter(oldItem => {
            return oldItem.type != data.type || oldItem.shop_id != data.shop_id
          })
          try {
            localShopsOrKOLColl.setItem(localShopsOrKOLColl, newShopsColl)
            const res = {
              status: 200,
              mess: "応援が取消しました"
            }
            resolve(res)
          } catch (e) {
            const res = {
              status: 400,
              mess: ""
            }
            reject(res)
          }
        })
      }
    },
    /**
   * 取得视频列表
   *
   * @returns
   */
    shopsGetVideoList (data) {
      return post("api/Shops/getVideoList", data);
    },
    /**
     * 取得直播列表
     *
     * @returns
     */
    shopsGetAliveList (data) {
      return post("api/Shops/getAliveList", data);
    },
    /**
     * 商品列表
     *
     * @returns
     */
    shopsGetGoodsList (data) {
      return post("api/Shops/getGoodsList", data);
    },
    /**
     * 关注商家接口
     *
     * @param {any} data
     * @returns
     */
    getshopcoll (data) {
      if (store.getters.token) {
        return post("api/CollShops/coll", data);
      } else {
        return new Promise((resolve, reject) => {
          let localShopsOrKOLColl = "LocalShopsOrKOLColl"
          data.type = "shop"
          var localShopsColl = localStorage.getItem(localShopsOrKOLColl)
          if (!localShopsColl) {
            localShopsColl = []
          }
          let index = localShopsColl.findIndex(item => {
            return data.shop_id == item.shop_id
          })
          if (index < 0) {
            localShopsColl.push(data)
          }
          console.log(localShopsColl)
          try {
            uni.setStorageSync(localShopsOrKOLColl, localShopsColl)
            const res = {
              status: 200,
              mess: "応援しました。"
            }
            resolve(res)
          } catch (e) {
            const res = {
              status: 400,
              mess: ""
            }
            reject(res)
          }
        })
      }
    },
    /**
   * 预约直播
   *
   * @returns
   */
    aliveScheduleAliveAppointment (data) {
      return post("api/AliveSchedule/aliveAppointment", data);
    },

    /**
     * 取消预约直播
     *
     * @returns
     */
    aliveScheduleAliveDisappointment (data) {
      return post("api/AliveSchedule/aliveDisappointment", data);
    },
    /**
     * 删除账号接口
     *
     * @param {any} data
     * @returns
     */
    getUnregister (data) {
      return post("api/Register/unregister", data);
    },
    getCategories () {
      return post("api/ApplyInfo/getCategories");
    },
    apply (data) {
      return post("api/ApplyInfo/apply", data);
    },

    getArticleByTag(tag) {
      return post('api/Article/getArticleByTag', {
        tag: tag,
      })
    }
  }
}
